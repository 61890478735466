import React from 'react'
import loadable from '@loadable/component'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Image from '@objects/image'

const UnorderedList = loadable(() => import('@objects/unorderedlist'))
const OrderedList = loadable(() => import('@objects/orderedlist'))
const BlockQuote = loadable(() => import('@objects/blockquote'))
const Separator = loadable(() => import('@objects/separator'))
const EmbeddedVideo = loadable(() => import('@objects/embeddedVideo'))
const Timeline = loadable(() => import('@components/timeline'))
const SinglePdf = loadable(() => import('@objects/singlePdf'))

function renderTextPart(content) {
  return content.map((c, i) => {
    if (c.nodeType === 'text' && c.value?.trim().length) {
      return c.marks?.length
        ? React.createElement(c.marks[0].type[0], { key: i }, c.value)
        : c.value
    } else if (c.nodeType === 'hyperlink') {
      return React.createElement(
        'a',
        {
          key: i,
          href: c.data.uri,
          target: c.data.uri.includes('www.') ? '_blank' : null,
        },
        c.content[0].value
      )
    } else {
      return null
    }
  })
}

function createFluidImageSrc(image) {
  return {
    aspectRatio: image.details.image.width / image.details.image.height,
    src: image.url + '?w=630&q=80',
    srcSet: `
        ${image.url}?w=${image.details.image.width / 4}&&q=80 ${
      image.details.image.width / 4
    }w,
        ${image.url}?w=${image.details.image.width / 2}&&q=80 ${
      image.details.image.width / 2
    }w,
        ${image.url}?w=${image.details.image.width}&&q=80 ${
      image.details.image.width
    }w,
        ${image.url}?w=${image.details.image.width * 1.5}&&q=80 ${
      image.details.image.width * 1.5
    }w,
        ${image.url}?w=1000&&q=80 1000w,
    `,
    sizes: '(max-width: 630px) 100vw, 630px',
  }
}

const ComponentParser = [
  {
    nodeType: 'paragraph',
    component: Copy,
    props: (component) => {
      return {
        children: renderTextPart(component.content),
        parseGlossary: true,
      }
    },
  },
  {
    nodeType: 'unordered-list',
    component: UnorderedList,
    props: (component) => {
      return {
        children: component.content.map((ct, i) => {
          return renderTextPart(ct.content[0].content)
        }),
        parseGlossary: true,
      }
    },
  },
  {
    nodeType: 'ordered-list',
    component: OrderedList,
    props: (component) => {
      return {
        children: component.content.map((ct, i) => {
          return renderTextPart(ct.content[0].content)
        }),
        parseGlossary: true,
      }
    },
  },
  {
    nodeType: 'heading-',
    component: Headline,
    props: (component) => {
      return {
        level: parseInt(
          component.nodeType.substring(component.nodeType.length - 1)
        ),
        id: component.content[0].value.replace(/\s+/g, '').toLowerCase(),
        children: component.content.map((ct) => ct.value),
      }
    },
  },
  {
    nodeType: 'hr',
    component: Separator,
    props: () => {
      return {}
    },
  },
  {
    nodeType: 'blockquote',
    component: BlockQuote,
    props: (component) => {
      return {
        text: component.content[0]?.content[0]?.value,
        author: component.content[1]?.content[0]?.value,
      }
    },
  },
  {
    nodeType: 'image$',
    component: Image,
    props: (component) => {
      return {
        image: component.data.target.fields.image?.de?.fields?.file?.de
          ? createFluidImageSrc(
              component.data.target.fields.image?.de?.fields?.file?.de
            )
          : null,
        alt: component.data.target.fields.image?.de?.fields?.description?.de,
        description: component.data.target.fields.shortDescription?.de,
        copyright: component.data.target.fields.copyright?.de,
      }
    },
  },
  {
    nodeType: 'pdf',
    component: SinglePdf,
    props: (component) => {
      const size =
        component.data.target.fields.file?.de?.fields?.file?.de?.details?.size

      return {
        title: component.data.target.fields.file?.de?.fields?.title?.de,
        description: component.data.target.fields?.description?.de,
        fileurl: component.data.target.fields.file?.de?.fields?.file?.de?.url,
        size: size ? (Math.round(size / 1024 / 10.24) / 100).toString() : null, // bytes to mb
        updatedAt: component.data.target.sys?.updatedAt,
      }
    },
  },
  {
    nodeType: 'video',
    component: EmbeddedVideo,
    props: (component) => {
      const placeholder = component.data.target.fields?.thumbnail?.de?.fields
      const videoTitle = component.data.target.fields?.title?.de
      const videoUrl =
        component.data.target.fields?.video?.de?.fields?.file?.de?.url

      const videoData = {
        title: videoTitle,
        video: {
          src: videoUrl,
        },
        placeholder: {
          image: placeholder?.file?.de
            ? createFluidImageSrc(placeholder?.file?.de)
            : null,
          alt: placeholder?.description?.de,
        },
      }

      return {
        data: videoData,
      }
    },
  },
  {
    nodeType: 'timeline',
    component: Timeline,
    props: (component) => {
      return {
        headlines: component.data.target.fields.headlines.de,
        copies: component.data.target.fields.copies.de,
      }
    },
  },
]

export { createFluidImageSrc }

export default ComponentParser
