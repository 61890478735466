import React from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Headline from '@objects/headline'
import Icon from '@objects/icon'
import breakpoint from '@utils/breakpoint'
import clsx from 'clsx'

const StyledDiv = styled.div`
  text-align: right;

  &.margin-top {
    margin-top: 30px;
    ${breakpoint('md')`
      margin-top: 0;
    `}
  }

  .downloadHeadline {
    font-size: ${({ theme }) => theme.fontSize['lg']};
  }

  .downloadItem {
  }
`

const StyledDownloadIcon = styled(Icon).attrs({
  name: 'Download',
  className: 'text-xl text-red',
})`
  margin-left: 4px;
  margin-bottom: 4px;
  font-size: 20px;
`

const contentType = (typeStr) => {
  let str = ''

  str = typeStr.includes('pdf') ? 'PDF' : str
  str = typeStr.includes('png') ? 'PNG' : str
  str = typeStr.includes('jpg') ? 'JPG' : str
  str = typeStr.includes('jpeg') ? 'JPG' : str
  str = typeStr.includes('mp4') ? 'MP4' : str

  return str
}

function PageDownload({ className, files, ...props }) {
  const intl = useIntl()

  const { marginTop = false } = props

  return (
    <StyledDiv
      className={clsx(['downloadContainer', { 'margin-top': marginTop }])}
      nopadding
      type="article"
      data-testid={'page-download-block'}
    >
      <Headline
        className={'downloadHeadline'}
        level={3}
        ariaLabel={intl.formatMessage({ id: 'article.downloads' })}
      >
        <FormattedMessage id="article.downloads" />
      </Headline>

      {(files &&
        files.map((fileObj, i) => (
          <React.Fragment key={fileObj.title + i}>
            {(contentType(fileObj.file.contentType) && (
              <a
                className={
                  'text-red text-sm font-medium inline-flex flex-row items-center relative'
                }
                href={fileObj.file.url}
                target="_blank"
                rel="noreferrer"
                data-testid={'page-download-single'}
              >
                {fileObj.title ? fileObj.title : fileObj.file.fileName} (
                {contentType(fileObj.file.contentType)}&nbsp;
                {(fileObj.file?.details.size / (1024 * 1024)).toFixed(2)}MB)
                <StyledDownloadIcon />
              </a>
            )) || <FormattedMessage id={'download.none'} />}
          </React.Fragment>
        ))) ||
        null}
    </StyledDiv>
  )
}

PageDownload.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array,
  marginTop: PropTypes.bool,
}

export default PageDownload
