import React from 'react'
import PageModuleParser from './PageModuleParser'

// eslint-disable-next-line react/prop-types
export default function PageModule({ pagemodule }) {
  const Parser = PageModuleParser.find((parsermodule) => {
    // eslint-disable-next-line react/prop-types
    return pagemodule.__typename === parsermodule.__typename
  })

  if (!Parser) {
    console.warn('Module not defined: ', pagemodule)
    return null
  }

  return React.createElement(Parser.component, Parser.props(pagemodule))
}
