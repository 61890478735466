import loadable from '@loadable/component'
import { getImage } from 'gatsby-plugin-image'

import Image from '@objects/image'
import YouTube from '@objects/youtube'

// Lazy-Load Components
const Timeline = loadable(() => import('@components/timeline'))
const SinglePdf = loadable(() => import('@objects/singlePdf'))
const EmbeddedVideo = loadable(() => import('@objects/embeddedVideo'))

export const getPropAsset = (value) => {
  const isImage = value?.file?.contentType.startsWith('image/')
  const asset = {
    title: value?.title,
    description: value?.description,
    file: value?.file,
  }
  if (isImage && value.svg) {
    return {
      ...asset,
      content: value.svg.content,
    }
  }

  return isImage && value.gatsbyImageData
    ? {
        ...asset,
        data: getImage(value.gatsbyImageData),
      }
    : asset
}

function createFluidImageSrc(image) {
  return {
    aspectRatio: image.details.image.width / image.details.image.height,
    src: image.url + '?w=630&q=80',
    srcSet: `
        ${image.url}?w=${image.details.image.width / 4}&&q=80 ${
      image.details.image.width / 4
    }w,
        ${image.url}?w=${image.details.image.width / 2}&&q=80 ${
      image.details.image.width / 2
    }w,
        ${image.url}?w=${image.details.image.width}&&q=80 ${
      image.details.image.width
    }w,
        ${image.url}?w=${image.details.image.width * 1.5}&&q=80 ${
      image.details.image.width * 1.5
    }w,
        ${image.url}?w=1000&&q=80 1000w,
    `,
    sizes: '(max-width: 630px) 100vw, 630px',
  }
}

const PageModuleParser = [
  {
    __typename: 'ContentfulImage',
    component: Image,
    props: (mod) => {
      return {
        image: mod.image?.fluid,
        alt: mod.image?.description,
        description: mod.shortDescription?.de,
        copyright: mod.copyright,
      }
    },
  },
  {
    __typename: 'ContentfulTimeline',
    component: Timeline,
    props: (mod) => {
      return {
        headlines: mod.headlines,
        copies: mod.copies,
      }
    },
  },
  {
    __typename: 'ContentfulPdf',
    component: SinglePdf,
    props: (mod) => {
      const size = mod.file?.file?.details?.size

      return {
        title: mod.file?.title,
        description: mod.description,
        fileurl: mod.file?.file?.url,
        size: size ? (Math.round(size / 1024 / 10.24) / 100).toString() : null, // bytes to mb
        updatedAt: mod.updatedAt,
      }
    },
  },
  {
    __typename: 'ContentfulVideo',
    component: EmbeddedVideo,
    props: (mod) => {
      const placeholder = mod.thumbnail
      const videoTitle = mod.title
      const videoCopy = mod.copy
      const videoUrl = mod.video?.file?.url

      const videoData = {
        title: videoTitle,
        copy: videoCopy,
        video: {
          src: videoUrl,
        },
        placeholder: {
          image: placeholder.thumbnail?.file
            ? createFluidImageSrc(placeholder.thumbnail?.file)
            : null,
          alt: placeholder?.description,
        },
      }

      return {
        data: videoData,
      }
    },
  },
  {
    __typename: 'ContentfulYouTube',
    component: YouTube,
    props: (mod) => {
      return {
        youtubeId: mod.youTubeId,
        videoDescription: mod.videoDescription,
      }
    },
  },
]

export default PageModuleParser
