import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import text from '@utils/text'

import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'
import useDateFns from '@hooks/useDateFns'
import { useTheme } from '@hooks'

import Headline from '@objects/headline'
import { TeaserCopy } from '@objects/copy'
import Button from '@objects/button'
import Icon from '@objects/icon'

const StyledDataWrapper = styled.div`
  ${text('metalg')}
`

function EventCard({
  className,
  link,
  image,
  headline,
  copy,
  duration,
  location,
}) {
  const intl = useIntl()
  const [aspectRatio, setAspectRatio] = useState(image?.aspectRatio ?? 0)
  const { screens } = useTheme()
  const { formatDate, isSameDay } = useDateFns()

  useEffect(() => {
    setAspectRatio(
      window.innerWidth >= parseInt(screens.md, 10) ? 1 : image?.aspectRatio
    )
  }, [])

  function renderDateTime() {
    if (isSameDay(duration.start, duration.end)) {
      return (
        formatDate(duration.start, 'dd.MM.yyyy, H:mm - ') +
        formatDate(duration.end, 'H:mm')
      )
    } else {
      return (
        formatDate(duration.start, 'dd.MM.yyyy - ') + formatDate(duration.end)
      )
    }
  }

  return (
    <div
      className={clsx(className, {
        'md:flex md:items-center md:space-x-7 md:py-1': image,
        'md:pt-1': !image,
      })}
      data-testid="event"
    >
      {image && (
        <div className={'md:w-3/8'}>
          <Link className="block overflow-hidden" to={link}>
            <Img
              fluid={{ ...image.fluid, aspectRatio, media: `(min-width: 0px)` }}
              alt={image.description}
            />
          </Link>
        </div>
      )}
      <div
        className={clsx({
          'md:w-5/8 pt-2 md:pt-0': image,
        })}
      >
        <Link to={link} className="block">
          <Headline level={5} className={'mb-2'} ariaLabel={headline}>
            {headline}
          </Headline>
        </Link>
        <TeaserCopy
          className={'mb-3'}
          noHrefArrow={true}
          truncate={25}
          html={copy}
        />
        <StyledDataWrapper className="flex mb-3 justify-between sm:justify-between">
          <div className={'sm:w-3/5 flex'}>
            <Icon name="Event" className={'mr-1 text-2lg'} />
            {renderDateTime()}
          </div>
          <div className={'sm:w-2/5 flex'}>
            <Icon name="Location" className={'mr-1 text-2lg'} />
            {location}
          </div>
        </StyledDataWrapper>
        <Button link={link} textlink>
          {intl.formatMessage({ id: 'button.event' })}
        </Button>
      </div>
    </div>
  )
}

EventCard.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  image: PropTypes.object,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
  duration: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  location: PropTypes.string,
}
export default EventCard
