import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import styled from 'styled-components'

import BlogCard from './types/blog'
import EventCard from './types/event'
import TopicCard from './types/topic'

function TeaserCard({
  className,
  type,
  image,
  stageImage,
  headline,
  copy,
  truncate,
  buttonLabel,
  link,
  date,
  duration,
  location,
  tag,
  square,
  reverse,
  parallax,
  bigTeaser,
  ...props
}) {
  function renderType() {
    switch (type) {
      case 'topic':
        return (
          <TopicCard
            className={className}
            link={link}
            image={image}
            headline={headline}
            copy={copy}
            buttonLabel={buttonLabel}
            reverse={reverse}
          />
        )
      case 'stageTopic':
        return (
          <TopicCard
            className={className}
            link={`/blog/${link}/`}
            image={stageImage}
            headline={headline}
            copy={copy}
            buttonLabel={buttonLabel}
            reverse={reverse}
            bigTeaser={bigTeaser}
          />
        )
      case 'event':
        return (
          <EventCard
            className={className}
            link={`/veranstaltungen/${link}/`}
            image={image}
            headline={headline}
            copy={copy}
            duration={duration}
            location={location}
          />
        )
      case 'blog':
        return (
          <BlogCard
            className={className}
            link={`/blog/${link}/`}
            image={image}
            headline={headline}
            copy={copy}
            tag={tag}
            date={date}
            square={square}
            reverse={reverse}
            parallax={parallax}
            bigTeaser={bigTeaser}
            {...props}
          />
        )
      case 'blogShort':
        return (
          <BlogCard
            className={className}
            link={`/blog/${link}/`}
            image={image}
            headline={headline}
            tag={tag}
            reverse={reverse}
          />
        )
      case 'easyLanguage':
        return (
          <BlogCard
            className={className}
            link={`/leichte-sprache/${link}/`}
            image={image}
            headline={headline}
            copy={copy}
            tag={tag}
            disableLinkTag
            date={date}
            square={square}
            reverse={reverse}
            parallax={parallax}
            {...props}
          />
        )
      default:
        return null
    }
  }
  return renderType()
}

TeaserCard.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'topic',
    'event',
    'blog',
    'blogShort',
    'easyLanguage',
  ]),
  link: PropTypes.string.isRequired,
  image: PropTypes.object,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
  buttonLabel: PropTypes.string,
  date: PropTypes.string,
  duration: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  location: PropTypes.string,
  tag: PropTypes.string,
  reverse: PropTypes.bool,
  square: PropTypes.oneOf(['sm', 'md']),
  parallax: PropTypes.bool,
  bigTeaser: PropTypes.string,
}

export default styled(TeaserCard)`
  .gatsby-image-wrapper {
    ${tw`
      min-w-full transition-transform duration-700 ease-in-out
    `}
    transform: scale(1);
  }
`
