import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import useDateFns from '@hooks/useDateFns'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { ArticleJsonLd, SpeakableJsonLd } from 'gatsby-plugin-next-seo'

import ContenfulComponentArray from '@tools/ContentfulComponentArray'
import { GlossaryScopeProvider } from '@providers/glossaryScopeProvider'

import Container from '@objects/container'
import Stage from '@components/stage'
import DoubleBlogCard from '@components/doubleBlogCard'
import PageDownload from '@components/pageDownload'

import renderRichText from '@utils/richtext'

const StyledContentContainer = styled(Container).attrs({
  content: true,
  nopadding: true,
  className: 'mb-11 md:mb-13',
})`
  h2 {
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    line-height: ${({ theme }) => theme.lineHeight['9']};
    margin-top: ${({ theme }) => theme.spacing['9']};
  }
  p a {
    display: inline-flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  p a::after {
    content: '';
    position: relative;
    display: inline-flex;
    width: ${({ theme }) => theme.width['5']};
    height: ${({ theme }) => theme.height['5']};
    margin-bottom: ${({ theme }) => theme.width['px']};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z' fill='%23e3000f'/%3E%3C/svg%3E");
  }
`

function ArticlePage({ data, pageContext, path }) {
  const intl = useIntl()
  const { formatDate } = useDateFns()
  const article = get(data, 'contentfulArticle')
  const articlecontent = article?.copy
  const pdf = article?.pdf

  const pageType = path.split('/')[1] // blog/newsroom

  const articleStage = {
    type: article.image?.fluid ? 'authorimage' : 'author',
    image: {
      ...article.image,
      fluid: {
        ...article?.image?.fluid,
        aspectRatio: 1,
      },
    },
    article: {
      headline: article.title,
      author: {
        link: null,
        name: article.author,
        image: article.authorImage,
        href: pageContext.path,
        date: formatDate(article.createdAt),
      },
      tag: {
        name: article.category?.title,
      },
    },
  }

  const articleCards = {
    headline: intl.formatMessage({ id: `article.related.${pageType}` }),
    button: {
      label: intl.formatMessage({ id: `button.${pageType}` }),
      to: `/${pageType}`,
    },
    cards: (() => {
      return article.relatedArticles?.map((card) => ({
        image: card.image,
        headline: card.title,
        copy: card.teaserCopy?.childMarkdownRemark?.html,
        tag: card.category?.title,
        date: card.createdAt,
        link: card.slug,
      }))
    })(),
  }

  const downloads = []
  pdf && downloads.push(pdf)

  return (
    <article aria-label="Artikel Seite">
      <ArticleJsonLd
        url={path}
        headline={articleStage.article?.headline}
        overrides={{
          '@type': 'NewsArticle',
        }}
      />
      <SpeakableJsonLd cssSelector={['article']} />

      <Stage {...articleStage} />

      <StyledContentContainer content nopadding className="mb-11 md:mb-13">
        {/* {articlecontent && (
          <GlossaryScopeProvider>
            <ContenfulComponentArray componentarray={articlecontent} />
          </GlossaryScopeProvider>
        )} */}
        {articlecontent && renderRichText(articlecontent)}
        {(!!downloads.length && <PageDownload files={downloads} />) || null}
      </StyledContentContainer>
      {articleCards.cards && (
        <DoubleBlogCard
          className="mb-11"
          headline={articleCards.headline}
          button={articleCards.button}
          cards={articleCards.cards}
        />
      )}
    </article>
  )
}

ArticlePage.propTypes = {
  path: PropTypes.any,
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!, $locale: String!) {
    contentfulArticle(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      createdAt
      title
      author
      authorImage {
        fluid(quality: 80, maxWidth: 400) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      category {
        title
      }
      image {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      copy {
        raw
        references {
          ...RichTextReferencesFragment
        }
      }
      relatedArticles {
        id
        slug
        title
        image {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
          description
        }
        updatedAt
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        category {
          title
        }
      }
      pdf {
        file {
          contentType
          url
          details {
            size
          }
        }
        title
      }
    }
  }
`
