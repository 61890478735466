import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@hooks/index'

const YoutubePlayer = ({ youtubeId, videoDescription }) => {
  const { screens } = useTheme()
  const [isMobile, setIsMobile] = useState(false)
  const ytiFrame = useRef()

  useEffect(() => {
    setIsMobile(window.innerWidth < parseInt(screens.md, 9))
  }, [])

  const opts = {
    playerVars: {
      autoplay: 0,
    },
  }

  return (
    <div className="w-full flex flex-col items-center">
      <iframe
        ref={ytiFrame}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
        allowFullScreen
        height={!isMobile && '360'}
        width={!isMobile && '640'}
      ></iframe>
      {videoDescription && (
        <p
          style={{
            textAlign: 'center',
            fontSize: '12px',
            width: '80%',
            lineHeight: 1.2,
          }}
        >
          {videoDescription}
        </p>
      )}
    </div>
  )
}
YoutubePlayer.propTypes = {
  youtubeId: PropTypes.string.isRequired,
  videoDescription: PropTypes.string,
}
export default YoutubePlayer
