import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'
import text from '@utils/text'

import Img from 'gatsby-image'
import { Link } from 'gatsby'
import useDateFns from '@hooks/useDateFns'
import { useTheme } from '@hooks'

import Tag from '@objects/tag'
import Headline from '@objects/headline'
import { TeaserCopy } from '@objects/copy'
import Button from '@objects/button'
import Parallax from '@objects/parallax'

const StyledDataWrapper = styled.div.attrs({
  className: 'flex flex-wrap justify-between my-2',
})`
  ${text('metamd')}
`

function BlogCard({
  className,
  link,
  image,
  headline,
  copy,
  date,
  tag,
  disableLinkTag,
  reverse,
  square,
  parallax,
  bigTeaser,
  ...props
}) {
  const [aspectRatio, setAspectRatio] = useState(image?.fluid?.aspectRatio ?? 0)
  const { screens } = useTheme()
  const { formatDate } = useDateFns()

  useEffect(() => {
    if (square || reverse) {
      setAspectRatio(
        (window.innerWidth >= parseInt(screens.md, 10) &&
          (reverse || square === 'md')) ||
          (window.innerWidth < parseInt(screens.md, 10) && square === 'sm') ||
          !copy
          ? 1
          : image?.aspectRatio
      )
    }
  }, [])

  function renderImage() {
    if (parallax) {
      return (
        <Parallax aspectRatio={aspectRatio}>
          <Link className={'overflow-hidden block'} to={link} tabIndex={-1}>
            <Img
              fluid={{ ...image.fluid, aspectRatio, media: `(min-width: 0px)` }}
              alt={image.description}
              imgStyle={{ objectPosition: 'center', objectFit: 'contain' }}
            />
          </Link>
        </Parallax>
      )
    }
    return (
      <Link
        className={'overflow-hidden block md:h-full lg:h-full'}
        to={link}
        tabIndex={-1}
        aria-label={image.description}
      >
        <Img
          className={'md:h-full lg:h-full'}
          fluid={{ ...image.fluid, aspectRatio, media: `(min-width: 0px)` }}
          alt={image.description}
          imgStyle={{ objectPosition: 'center', objectFit: 'contain' }}
        />
      </Link>
    )
  }

  return (
    <div
      className={clsx(className, {
        flex: !copy,
        'flex-row-reverse': !copy,
        'md:flex': (reverse && copy) || (bigTeaser && copy),
        'md:flex-row-reverse': reverse && copy,
      })}
      data-testid={copy ? 'blog' : 'blogShort'}
      {...props}
    >
      {image && (
        <div
          className={clsx({
            'md:w-1/2': reverse && copy && !bigTeaser,
            'w-2/7 md:w-1/3': !copy,
            //'md:w-2/5 lg:w-2/5': bigTeaser,
            'md:w-1/2 lg:w-3/6': bigTeaser,
          })}
        >
          {renderImage()}
        </div>
      )}
      <div
        className={clsx({
          'md:w-1/2 md:pr-4': reverse && copy && image && !bigTeaser,
          'w-5/7 pr-4 md:w-2/3': !copy && image && !bigTeaser,
          'md:w-3/5 md:pr-4': bigTeaser,
          'flex-1': !image,
        })}
      >
        <StyledDataWrapper>
          <Tag
            to={disableLinkTag ? null : '/blog/'}
            filter={disableLinkTag ? null : tag}
          >
            {tag}
          </Tag>
          {date && formatDate(date)}
        </StyledDataWrapper>
        <Link to={link} className="block">
          <Headline
            level={copy && !bigTeaser ? 2 : 3}
            className={clsx(
              { 'mb-2': copy },
              copy && !bigTeaser
                ? 'text-2lg font-normal leading-5 md:text-xl md:leading-7'
                : 'text-lg font-medium leading-5'
            )}
            ariaLabel={headline}
          >
            {headline}
            {(!copy || square === 'sm') && (
              <Button
                className={clsx('align-top', {
                  'md:hidden': square === 'sm',
                })}
                textlink
              />
            )}
          </Headline>
        </Link>
        {copy && (
          <TeaserCopy
            className={clsx({
              'hidden md:block': square === 'sm',
            })}
            truncate={15}
            noHrefArrow={true}
            truncateextend={<Button textlink link={link} tabIndex={-1} />}
            html={copy}
          />
        )}
      </div>
    </div>
  )
}

BlogCard.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  image: PropTypes.object,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
  date: PropTypes.string,
  tag: PropTypes.string,
  disableLinkTag: PropTypes.bool,
  reverse: PropTypes.bool,
  square: PropTypes.oneOf(['sm', 'md']),
  parallax: PropTypes.bool,
  bigTeaser: PropTypes.string,
}
export default BlogCard
