/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { renderRichText as origRenderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

import parse from 'html-react-parser'

import PageModule from '@utils/PageModule'

import { Link } from 'gatsby'

import UnorderedList from '@objects/unorderedlist'
import OrderedList from '@objects/orderedlist'
import Headline from '@objects/headline'

import Separator from '@objects/separator'
import BlockQuote from '@objects/blockquote'
import Copy from '@objects/copy'

export default function renderRichText(richText) {
  //richText: BASE.Contentful.IRichtext<ModuleUnion>
  return origRenderRichText(richText, {
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      // eslint-disable-next-line react/display-name
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    },
    renderText: (text) => {
      return parse(
        text
          .replace(/\n/g, '<br>')
          .replace(/®/g, '<sup>®</sup>')
          .replace(/©/g, '<sup>©</sup>')
          .replace(/℗/g, '<sup>℗</sup>')
      )
    },
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <Copy parseGlossary>{children}</Copy>
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <Headline level={1} ariaLabel={node.content[0].value}>
            {children}
          </Headline>
        )
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <Headline level={2} ariaLabel={node.content[0].value}>
            {children}
          </Headline>
        )
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Headline level={3} ariaLabel={node.content[0].value}>
            {children}
          </Headline>
        )
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_4]: (node, children) => {
        return (
          <Headline level={4} ariaLabel={node.content[0].value}>
            {children}
          </Headline>
        )
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_5]: (node, children) => {
        return (
          <Headline level={5} ariaLabel={node.content[0].value}>
            {children}
          </Headline>
        )
      },

      // eslint-disable-next-line react/display-name
      [BLOCKS.HR]: (node, children) => {
        return <Separator></Separator>
      },

      // eslint-disable-next-line react/display-name
      [BLOCKS.QUOTE]: (node, children) => {
        return <BlockQuote text={children} />
      },

      // eslint-disable-next-line react/display-name
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList parseGlossary>{children}</UnorderedList>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList parseGlossary>{children}</OrderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => children,
      // eslint-disable-next-line react/display-name

      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target) {
          return (
            <PageModule
              key={node.data.target.id}
              pagemodule={node.data.target}
            />
          )
        }
        console.error('Missing embedded entry', node)
        return null
      },
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Link
            to={node.data.uri}
            isExternal
            target={
              /^(?!(tel:|fax:).*).*/g.test(node.data.uri) ? undefined : '_self'
            }
          >
            {children}
          </Link>
        )
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const link = node.data?.target?.fields?.fullPath
        return link ? <Link to={link}>{children}</Link> : children
      },
    },
  })
}
