import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Container from '@objects/container'
import TracklineWrapper from '@objects/tracklineWrapper'
import Headline from '@objects/headline'
import TeaserCard from '@objects/teaserCard'
import Button from '@objects/button'

const StyledTracklineWrapper = styled(TracklineWrapper).attrs({
  className: 'bg-gray-light-300',
})``

function DoubleBlogCard({ className, headline, button, cards, options }) {
  function renderTeaser() {
    return cards.map((card, i) => {
      return (
        <TeaserCard
          key={'teaser' + i}
          className="md:w-1/2"
          type="blog"
          {...card}
        />
      )
    })
  }

  return (
    <StyledTracklineWrapper
      topbottom={true}
      className={className}
      firstcircle="halfvert"
      secondcircle="t"
    >
      <Container role="region" aria-label={headline}>
        <Headline className="text-center mb-9" ariaLabel={headline}>
          {headline}
        </Headline>
        <div className="space-y-9 md:space-y-0 md:flex md:space-x-7 lg:w-10/12 lg:mx-auto">
          {renderTeaser()}
        </div>
        {button && (
          <div className="text-center mt-9">
            <Button
              primary={options?.primary}
              to={button.to}
              role="button"
              aria-label={button.label}
            >
              {button.label}
            </Button>
          </div>
        )}
      </Container>
    </StyledTracklineWrapper>
  )
}

DoubleBlogCard.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }),
  options: PropTypes.object,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      image: PropTypes.object,
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      date: PropTypes.string,
      tag: PropTypes.string,
    })
  ).isRequired,
}
export default DoubleBlogCard
