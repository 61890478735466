import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'

import Headline from '@objects/headline'
import { TeaserCopy } from '@objects/copy'
import Button from '@objects/button'

function TopicCard({
  className,
  link,
  image,
  headline,
  copy,
  buttonLabel,
  reverse,
  bigTeaser,
}) {
  const intl = useIntl()

  return (
    <div
      className={clsx(className, 'md:flex md:items-center', {
        'md:flex-row-reverse': reverse,
      })}
      data-testid="topic"
    >
      {image && (
        <div
          className={clsx(
            {
              'md:w-2/3': bigTeaser,
              'md:w-1/2': !bigTeaser,
            },

            {
              'md:ml-7': reverse,
              'md:mr-7': !reverse,
            }
          )}
        >
          <Link className="block overflow-hidden" to={link} tabIndex={-1}>
            <Img
              fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
              alt={image.description}
            />
          </Link>
        </div>
      )}
      <div className={clsx({ 'md:w-1/2': image })}>
        <div className={'pt-2 md:pt-0'}>
          <Link to={link} className="block">
            <Headline
              level={3}
              className={
                'mb-2 text-2lg font-normal leading-5 md:text-xl md:leading-7'
              }
              ariaLabel={headline}
            >
              {headline}
            </Headline>
          </Link>
          <TeaserCopy
            className={'mb-3'}
            noHrefArrow={true}
            html={copy}
            truncate={bigTeaser ? null : 15}
          />
          <Button link={link} textlink tabIndex={-1}>
            {buttonLabel || intl.formatMessage({ id: 'button.getmore' })}
          </Button>
        </div>
      </div>
    </div>
  )
}

TopicCard.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  image: PropTypes.object,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
  buttonLabel: PropTypes.string,
  reverse: PropTypes.bool,
  bigTeaser: PropTypes.string,
}
export default TopicCard
