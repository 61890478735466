import React from 'react'
import PropTypes from 'prop-types'
import QuoteIcon from '@static/img/icons/quoteicon.inline.svg'
import tw from 'twin.macro'
import { get } from 'lodash'
import styled from 'styled-components'

import Container from '@objects/container'

const StyledQuoteIcon = styled(QuoteIcon)`
  path,
  g {
    fill: ${({ theme }) => get(theme, 'colors.red.default')};
  }
  ${tw`
    absolute
  `}
`

const QuoteBefore = styled(StyledQuoteIcon)`
  top: 0;
  left: 0;
`

const QuoteAfter = styled(StyledQuoteIcon)`
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
`

const StyledQuote = styled.blockquote.attrs({
  className:
    'relative text-center text-2lg text-blue-default font-normal mb-4 px-8.5 leading-7 md:px-10 md:text-2xl md:leading-10',
})``

const StyledAuthor = styled.figcaption.attrs({
  className: 'leading-2 text-black-650 font-normal text-center',
})`
  font-size: 14px;
`

const BlockQuoteWrapper = styled.div.attrs({
  className: 'mb-8.5 md:mb-9',
})``

function Blockquote({ text, author }) {
  return (
    <BlockQuoteWrapper data-testid={'block-quote'}>
      <Container content nopadding>
        <figure>
          <StyledQuote>
            {text}
            <QuoteBefore />
            <QuoteAfter />
          </StyledQuote>
          <StyledAuthor>{author}</StyledAuthor>
        </figure>
      </Container>
    </BlockQuoteWrapper>
  )
}

Blockquote.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
}

export default Blockquote
