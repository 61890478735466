import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import get from 'lodash/get'

function Separator({ className }) {
  return <div className={className}></div>
}

Separator.propTypes = {
  className: PropTypes.string,
}

export default styled(Separator)`
  ${tw`my-7`}
  height: 1px;
  background: ${({ theme }) => get(theme, `colors['gray-light']['650']`)};
`
