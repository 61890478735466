import React from 'react'
import PropTypes from 'prop-types'

import ContentfulComponent from './ContentfulComponent'

function ContenfulComponentArray({ className, componentarray }) {
  function renderComponents() {
    return componentarray.map((component, i) => {
      return (
        <ContentfulComponent
          key={`${component.nodeType}J${i}`}
          className={className}
          component={component}
        />
      )
    })
  }
  return <>{renderComponents()}</>
}

ContenfulComponentArray.propTypes = {
  className: PropTypes.string,
  componentarray: PropTypes.array.isRequired,
}

export default ContenfulComponentArray
